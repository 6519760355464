$( document ).ready(function() {
  // console.log("-------------")

  // $('#identification').keypress(function(){
  //   let val = $(this).val().toLowerCase()
  //   $('#identification').val(val)
  //   console.log(val)
  // })


});
