enifed('ember-routing/system/router', ['exports', 'ember-utils', 'ember-console', 'ember-metal', 'ember-debug', 'ember-runtime', 'ember-routing/system/route', 'ember-routing/system/dsl', 'ember-routing/location/api', 'ember-routing/utils', 'ember-routing/system/router_state', 'router'], function (exports, _emberUtils, _emberConsole, _emberMetal, _emberDebug, _emberRuntime, _route, _dsl, _api, _utils, _router_state, _router) {
  'use strict';

  exports.triggerEvent = triggerEvent;


  function K() {
    return this;
  }

  /**
  @module ember
  @submodule ember-routing
  */

  var slice = Array.prototype.slice;


  /**
    The `Ember.Router` class manages the application state and URLs. Refer to
    the [routing guide](https://emberjs.com/guides/routing/) for documentation.
  
    @class Router
    @namespace Ember
    @extends Ember.Object
    @uses Ember.Evented
    @public
  */
  var EmberRouter = _emberRuntime.Object.extend(_emberRuntime.Evented, {
    /**
      The `location` property determines the type of URL's that your
      application will use.
       The following location types are currently available:
       * `history` - use the browser's history API to make the URLs look just like any standard URL
      * `hash` - use `#` to separate the server part of the URL from the Ember part: `/blog/#/posts/new`
      * `none` - do not store the Ember URL in the actual browser URL (mainly used for testing)
      * `auto` - use the best option based on browser capabilities: `history` if possible, then `hash` if possible, otherwise `none`
       This value is defaulted to `auto` by the `locationType` setting of `/config/environment.js`
       @property location
      @default 'hash'
      @see {Ember.Location}
      @public
    */
    location: 'hash',

    /**
     Represents the URL of the root of the application, often '/'. This prefix is
     assumed on all routes defined on this router.
      @property rootURL
     @default '/'
     @public
    */
    rootURL: '/',

    _initRouterJs: function () {
      var routerMicrolib = this._routerMicrolib = new _router.default();
      routerMicrolib.triggerEvent = triggerEvent;

      routerMicrolib._triggerWillChangeContext = K;
      routerMicrolib._triggerWillLeave = K;

      var dslCallbacks = this.constructor.dslCallbacks || [K];
      var dsl = this._buildDSL();

      dsl.route('application', { path: '/', resetNamespace: true, overrideNameAssertion: true }, function () {
        for (var i = 0; i < dslCallbacks.length; i++) {
          dslCallbacks[i].call(this);
        }
      });

      if (true) {
        if ((0, _emberMetal.get)(this, 'namespace.LOG_TRANSITIONS_INTERNAL')) {
          routerMicrolib.log = _emberConsole.default.debug;
        }
      }

      routerMicrolib.map(dsl.generate());
    },
    _buildDSL: function () {
      var moduleBasedResolver = this._hasModuleBasedResolver();
      var options = {
        enableLoadingSubstates: !!moduleBasedResolver
      };

      var owner = (0, _emberUtils.getOwner)(this);
      var router = this;

      options.resolveRouteMap = function (name) {
        return owner.factoryFor('route-map:' + name);
      };

      options.addRouteForEngine = function (name, engineInfo) {
        if (!router._engineInfoByRoute[name]) {
          router._engineInfoByRoute[name] = engineInfo;
        }
      };

      return new _dsl.default(null, options);
    },
    init: function () {
      this._super.apply(this, arguments);

      this.currentURL = null;
      this.currentRouteName = null;
      this.currentPath = null;

      this._qpCache = Object.create(null);
      this._resetQueuedQueryParameterChanges();
      this._handledErrors = (0, _emberUtils.dictionary)(null);
      this._engineInstances = Object.create(null);
      this._engineInfoByRoute = Object.create(null);
    },
    _resetQueuedQueryParameterChanges: function () {
      this._queuedQPChanges = {};
    },


    /**
      Represents the current URL.
       @method url
      @return {String} The current URL.
      @private
    */
    url: (0, _emberMetal.computed)(function () {
      return (0, _emberMetal.get)(this, 'location').getURL();
    }),

    _hasModuleBasedResolver: function () {
      var owner = (0, _emberUtils.getOwner)(this);

      if (!owner) {
        return false;
      }

      var resolver = owner.application && owner.application.__registry__ && owner.application.__registry__.resolver;

      if (!resolver) {
        return false;
      }

      return !!resolver.moduleBasedResolver;
    },
    startRouting: function () {
      var initialURL = (0, _emberMetal.get)(this, 'initialURL');

      if (this.setupRouter()) {
        if (initialURL === undefined) {
          initialURL = (0, _emberMetal.get)(this, 'location').getURL();
        }
        var initialTransition = this.handleURL(initialURL);
        if (initialTransition && initialTransition.error) {
          throw initialTransition.error;
        }
      }
    },
    setupRouter: function () {
      var _this = this;

      this._initRouterJs();
      this._setupLocation();

      var location = (0, _emberMetal.get)(this, 'location');

      // Allow the Location class to cancel the router setup while it refreshes
      // the page
      if ((0, _emberMetal.get)(location, 'cancelRouterSetup')) {
        return false;
      }

      this._setupRouter(location);

      location.onUpdateURL(function (url) {
        _this.handleURL(url);
      });

      return true;
    },
    didTransition: function (infos) {
      updatePaths(this);

      this._cancelSlowTransitionTimer();

      this.notifyPropertyChange('url');
      this.set('currentState', this.targetState);

      // Put this in the runloop so url will be accurate. Seems
      // less surprising than didTransition being out of sync.
      _emberMetal.run.once(this, this.trigger, 'didTransition');

      if (true) {
        if ((0, _emberMetal.get)(this, 'namespace').LOG_TRANSITIONS) {
          _emberConsole.default.log('Transitioned into \'' + EmberRouter._routePath(infos) + '\'');
        }
      }
    },
    _setOutlets: function () {
      // This is triggered async during Ember.Route#willDestroy.
      // If the router is also being destroyed we do not want to
      // to create another this._toplevelView (and leak the renderer)
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      var handlerInfos = this._routerMicrolib.currentHandlerInfos;
      var route = void 0;
      var defaultParentState = void 0;
      var liveRoutes = null;

      if (!handlerInfos) {
        return;
      }

      for (var i = 0; i < handlerInfos.length; i++) {
        route = handlerInfos[i].handler;
        var connections = route.connections;
        var ownState = void 0;
        for (var j = 0; j < connections.length; j++) {
          var appended = appendLiveRoute(liveRoutes, defaultParentState, connections[j]);
          liveRoutes = appended.liveRoutes;
          if (appended.ownState.render.name === route.routeName || appended.ownState.render.outlet === 'main') {
            ownState = appended.ownState;
          }
        }
        if (connections.length === 0) {
          ownState = representEmptyRoute(liveRoutes, defaultParentState, route);
        }
        defaultParentState = ownState;
      }

      // when a transitionTo happens after the validation phase
      // during the initial transition _setOutlets is called
      // when no routes are active. However, it will get called
      // again with the correct values during the next turn of
      // the runloop
      if (!liveRoutes) {
        return;
      }

      if (!this._toplevelView) {
        var owner = (0, _emberUtils.getOwner)(this);
        var OutletView = owner.factoryFor('view:-outlet');
        this._toplevelView = OutletView.create();
        this._toplevelView.setOutletState(liveRoutes);
        var instance = owner.lookup('-application-instance:main');
        instance.didCreateRootView(this._toplevelView);
      } else {
        this._toplevelView.setOutletState(liveRoutes);
      }
    },
    willTransition: function (oldInfos, newInfos, transition) {
      _emberMetal.run.once(this, this.trigger, 'willTransition', transition);

      if (true) {
        if ((0, _emberMetal.get)(this, 'namespace').LOG_TRANSITIONS) {
          _emberConsole.default.log('Preparing to transition from \'' + EmberRouter._routePath(oldInfos) + '\' to \'' + EmberRouter._routePath(newInfos) + '\'');
        }
      }
    },
    handleURL: function (url) {
      // Until we have an ember-idiomatic way of accessing #hashes, we need to
      // remove it because router.js doesn't know how to handle it.
      var _url = url.split(/#(.+)?/)[0];
      return this._doURLTransition('handleURL', _url);
    },
    _doURLTransition: function (routerJsMethod, url) {
      var transition = this._routerMicrolib[routerJsMethod](url || '/');
      didBeginTransition(transition, this);
      return transition;
    },
    transitionTo: function () {
      var queryParams = void 0;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var arg = args[0];
      if (resemblesURL(arg)) {
        return this._doURLTransition('transitionTo', arg);
      }

      var possibleQueryParams = args[args.length - 1];
      if (possibleQueryParams && possibleQueryParams.hasOwnProperty('queryParams')) {
        queryParams = args.pop().queryParams;
      } else {
        queryParams = {};
      }

      var targetRouteName = args.shift();
      return this._doTransition(targetRouteName, args, queryParams);
    },
    intermediateTransitionTo: function () {
      var _routerMicrolib;

      (_routerMicrolib = this._routerMicrolib).intermediateTransitionTo.apply(_routerMicrolib, arguments);

      updatePaths(this);

      if (true) {
        var infos = this._routerMicrolib.currentHandlerInfos;
        if ((0, _emberMetal.get)(this, 'namespace').LOG_TRANSITIONS) {
          _emberConsole.default.log('Intermediate-transitioned into \'' + EmberRouter._routePath(infos) + '\'');
        }
      }
    },
    replaceWith: function () {
      return this.transitionTo.apply(this, arguments).method('replace');
    },
    generate: function () {
      var _routerMicrolib2;

      var url = (_routerMicrolib2 = this._routerMicrolib).generate.apply(_routerMicrolib2, arguments);
      return this.location.formatURL(url);
    },
    isActive: function () {
      var _routerMicrolib3;

      return (_routerMicrolib3 = this._routerMicrolib).isActive.apply(_routerMicrolib3, arguments);
    },
    isActiveIntent: function (routeName, models, queryParams) {
      return this.currentState.isActiveIntent(routeName, models, queryParams);
    },
    send: function () {
      var _routerMicrolib4;

      /*name, context*/
      (_routerMicrolib4 = this._routerMicrolib).trigger.apply(_routerMicrolib4, arguments);
    },
    hasRoute: function (route) {
      return this._routerMicrolib.hasRoute(route);
    },
    reset: function () {
      if (this._routerMicrolib) {
        this._routerMicrolib.reset();
      }
    },
    willDestroy: function () {
      if (this._toplevelView) {
        this._toplevelView.destroy();
        this._toplevelView = null;
      }

      this._super.apply(this, arguments);

      this.reset();

      var instances = this._engineInstances;
      for (var name in instances) {
        for (var id in instances[name]) {
          (0, _emberMetal.run)(instances[name][id], 'destroy');
        }
      }
    },
    _activeQPChanged: function (queryParameterName, newValue) {
      this._queuedQPChanges[queryParameterName] = newValue;
      _emberMetal.run.once(this, this._fireQueryParamTransition);
    },
    _updatingQPChanged: function (queryParameterName) {
      if (!this._qpUpdates) {
        this._qpUpdates = {};
      }
      this._qpUpdates[queryParameterName] = true;
    },
    _fireQueryParamTransition: function () {
      this.transitionTo({ queryParams: this._queuedQPChanges });
      this._resetQueuedQueryParameterChanges();
    },
    _setupLocation: function () {
      var location = (0, _emberMetal.get)(this, 'location');
      var rootURL = (0, _emberMetal.get)(this, 'rootURL');
      var owner = (0, _emberUtils.getOwner)(this);

      if ('string' === typeof location && owner) {
        var resolvedLocation = owner.lookup('location:' + location);

        if (resolvedLocation !== undefined) {
          location = (0, _emberMetal.set)(this, 'location', resolvedLocation);
        } else {
          // Allow for deprecated registration of custom location API's
          var options = {
            implementation: location
          };

          location = (0, _emberMetal.set)(this, 'location', _api.default.create(options));
        }
      }

      if (location !== null && typeof location === 'object') {
        if (rootURL) {
          (0, _emberMetal.set)(location, 'rootURL', rootURL);
        }

        // Allow the location to do any feature detection, such as AutoLocation
        // detecting history support. This gives it a chance to set its
        // `cancelRouterSetup` property which aborts routing.
        if (typeof location.detect === 'function') {
          location.detect();
        }

        // ensure that initState is called AFTER the rootURL is set on
        // the location instance
        if (typeof location.initState === 'function') {
          location.initState();
        }
      }
    },
    _getHandlerFunction: function () {
      var _this2 = this;

      var seen = Object.create(null);
      var owner = (0, _emberUtils.getOwner)(this);

      return function (name) {
        var routeName = name;
        var routeOwner = owner;
        var engineInfo = _this2._engineInfoByRoute[routeName];

        if (engineInfo) {
          var engineInstance = _this2._getEngineInstance(engineInfo);

          routeOwner = engineInstance;
          routeName = engineInfo.localFullName;
        }

        var fullRouteName = 'route:' + routeName;

        var handler = routeOwner.lookup(fullRouteName);

        if (seen[name]) {
          return handler;
        }

        seen[name] = true;

        if (!handler) {
          var DefaultRoute = routeOwner.factoryFor('route:basic').class;
          routeOwner.register(fullRouteName, DefaultRoute.extend());
          handler = routeOwner.lookup(fullRouteName);

          if (true) {
            if ((0, _emberMetal.get)(_this2, 'namespace.LOG_ACTIVE_GENERATION')) {
              (0, _emberDebug.info)('generated -> ' + fullRouteName, { fullName: fullRouteName });
            }
          }
        }

        handler._setRouteName(routeName);

        if (engineInfo && !(0, _route.hasDefaultSerialize)(handler)) {
          throw new Error('Defining a custom serialize method on an Engine route is not supported.');
        }

        return handler;
      };
    },
    _getSerializerFunction: function () {
      var _this3 = this;

      return function (name) {
        var engineInfo = _this3._engineInfoByRoute[name];

        // If this is not an Engine route, we fall back to the handler for serialization
        if (!engineInfo) {
          return;
        }

        return engineInfo.serializeMethod || _route.defaultSerialize;
      };
    },
    _setupRouter: function (location) {
      var _this4 = this;

      var lastURL = void 0;
      var routerMicrolib = this._routerMicrolib;

      routerMicrolib.getHandler = this._getHandlerFunction();
      routerMicrolib.getSerializer = this._getSerializerFunction();

      var doUpdateURL = function () {
        location.setURL(lastURL);
        (0, _emberMetal.set)(_this4, 'currentURL', lastURL);
      };

      routerMicrolib.updateURL = function (path) {
        lastURL = path;
        _emberMetal.run.once(doUpdateURL);
      };

      if (location.replaceURL) {
        var doReplaceURL = function () {
          location.replaceURL(lastURL);
          (0, _emberMetal.set)(_this4, 'currentURL', lastURL);
        };

        routerMicrolib.replaceURL = function (path) {
          lastURL = path;
          _emberMetal.run.once(doReplaceURL);
        };
      }

      routerMicrolib.didTransition = function (infos) {
        _this4.didTransition(infos);
      };

      routerMicrolib.willTransition = function (oldInfos, newInfos, transition) {
        _this4.willTransition(oldInfos, newInfos, transition);
      };
    },
    _serializeQueryParams: function (handlerInfos, queryParams) {
      var _this5 = this;

      forEachQueryParam(this, handlerInfos, queryParams, function (key, value, qp) {
        if (qp) {
          delete queryParams[key];
          queryParams[qp.urlKey] = qp.route.serializeQueryParam(value, qp.urlKey, qp.type);
        } else if (value === undefined) {
          return; // We don't serialize undefined values
        } else {
          queryParams[key] = _this5._serializeQueryParam(value, (0, _emberRuntime.typeOf)(value));
        }
      });
    },
    _serializeQueryParam: function (value, type) {
      if (type === 'array') {
        return JSON.stringify(value);
      }

      return '' + value;
    },
    _deserializeQueryParams: function (handlerInfos, queryParams) {
      forEachQueryParam(this, handlerInfos, queryParams, function (key, value, qp) {
        // If we don't have QP meta info for a given key, then we do nothing
        // because all values will be treated as strings
        if (qp) {
          delete queryParams[key];
          queryParams[qp.prop] = qp.route.deserializeQueryParam(value, qp.urlKey, qp.type);
        }
      });
    },
    _deserializeQueryParam: function (value, defaultType) {
      if (defaultType === 'boolean') {
        return value === 'true';
      } else if (defaultType === 'number') {
        return Number(value).valueOf();
      } else if (defaultType === 'array') {
        return (0, _emberRuntime.A)(JSON.parse(value));
      }
      return value;
    },
    _pruneDefaultQueryParamValues: function (handlerInfos, queryParams) {
      var qps = this._queryParamsFor(handlerInfos);
      for (var key in queryParams) {
        var qp = qps.map[key];
        if (qp && qp.serializedDefaultValue === queryParams[key]) {
          delete queryParams[key];
        }
      }
    },
    _doTransition: function (_targetRouteName, models, _queryParams, _keepDefaultQueryParamValues) {
      var _routerMicrolib5;

      var targetRouteName = _targetRouteName || (0, _utils.getActiveTargetName)(this._routerMicrolib);
      (true && !(targetRouteName && this._routerMicrolib.hasRoute(targetRouteName)) && (0, _emberDebug.assert)('The route ' + targetRouteName + ' was not found', targetRouteName && this._routerMicrolib.hasRoute(targetRouteName)));


      var queryParams = {};

      this._processActiveTransitionQueryParams(targetRouteName, models, queryParams, _queryParams);

      (0, _emberUtils.assign)(queryParams, _queryParams);
      this._prepareQueryParams(targetRouteName, models, queryParams, _keepDefaultQueryParamValues);

      var transitionArgs = (0, _utils.routeArgs)(targetRouteName, models, queryParams);
      var transition = (_routerMicrolib5 = this._routerMicrolib).transitionTo.apply(_routerMicrolib5, transitionArgs);

      didBeginTransition(transition, this);

      return transition;
    },
    _processActiveTransitionQueryParams: function (targetRouteName, models, queryParams, _queryParams) {
      // merge in any queryParams from the active transition which could include
      // queryParams from the url on initial load.
      if (!this._routerMicrolib.activeTransition) {
        return;
      }

      var unchangedQPs = {};
      var qpUpdates = this._qpUpdates || {};
      var params = this._routerMicrolib.activeTransition.queryParams;
      for (var key in params) {
        if (!qpUpdates[key]) {
          unchangedQPs[key] = params[key];
        }
      }

      // We need to fully scope queryParams so that we can create one object
      // that represents both passed-in queryParams and ones that aren't changed
      // from the active transition.
      this._fullyScopeQueryParams(targetRouteName, models, _queryParams);
      this._fullyScopeQueryParams(targetRouteName, models, unchangedQPs);
      (0, _emberUtils.assign)(queryParams, unchangedQPs);
    },
    _prepareQueryParams: function (targetRouteName, models, queryParams, _fromRouterService) {
      var state = calculatePostTransitionState(this, targetRouteName, models);
      this._hydrateUnsuppliedQueryParams(state, queryParams, _fromRouterService);
      this._serializeQueryParams(state.handlerInfos, queryParams);

      if (!_fromRouterService) {
        this._pruneDefaultQueryParamValues(state.handlerInfos, queryParams);
      }
    },
    _getQPMeta: function (handlerInfo) {
      var route = handlerInfo.handler;
      return route && (0, _emberMetal.get)(route, '_qp');
    },
    _queryParamsFor: function (handlerInfos) {
      var handlerInfoLength = handlerInfos.length;
      var leafRouteName = handlerInfos[handlerInfoLength - 1].name;
      var cached = this._qpCache[leafRouteName];
      if (cached) {
        return cached;
      }

      var shouldCache = true;
      var qpsByUrlKey = {};
      var map = {};
      var qps = [];

      for (var i = 0; i < handlerInfoLength; ++i) {
        var qpMeta = this._getQPMeta(handlerInfos[i]);

        if (!qpMeta) {
          shouldCache = false;
          continue;
        }

        // Loop over each QP to make sure we don't have any collisions by urlKey
        for (var _i = 0; _i < qpMeta.qps.length; _i++) {
          var qp = qpMeta.qps[_i];
          var urlKey = qp.urlKey;
          var qpOther = qpsByUrlKey[urlKey];

          if (qpOther && qpOther.controllerName !== qp.controllerName) {
            var otherQP = qpsByUrlKey[urlKey];
            (true && !(false) && (0, _emberDebug.assert)('You\'re not allowed to have more than one controller property map to the same query param key, but both `' + otherQP.scopedPropertyName + '` and `' + qp.scopedPropertyName + '` map to `' + urlKey + '`. You can fix this by mapping one of the controller properties to a different query param key via the `as` config option, e.g. `' + otherQP.prop + ': { as: \'other-' + otherQP.prop + '\' }`', false));
          }

          qpsByUrlKey[urlKey] = qp;
          qps.push(qp);
        }

        (0, _emberUtils.assign)(map, qpMeta.map);
      }

      var finalQPMeta = { qps: qps, map: map };

      if (shouldCache) {
        this._qpCache[leafRouteName] = finalQPMeta;
      }

      return finalQPMeta;
    },
    _fullyScopeQueryParams: function (leafRouteName, contexts, queryParams) {
      var state = calculatePostTransitionState(this, leafRouteName, contexts);
      var handlerInfos = state.handlerInfos;

      for (var i = 0, len = handlerInfos.length; i < len; ++i) {
        var qpMeta = this._getQPMeta(handlerInfos[i]);

        if (!qpMeta) {
          continue;
        }

        for (var j = 0, qpLen = qpMeta.qps.length; j < qpLen; ++j) {
          var qp = qpMeta.qps[j];

          var presentProp = qp.prop in queryParams && qp.prop || qp.scopedPropertyName in queryParams && qp.scopedPropertyName || qp.urlKey in queryParams && qp.urlKey;

          if (presentProp) {
            if (presentProp !== qp.scopedPropertyName) {
              queryParams[qp.scopedPropertyName] = queryParams[presentProp];
              delete queryParams[presentProp];
            }
          }
        }
      }
    },
    _hydrateUnsuppliedQueryParams: function (state, queryParams, _fromRouterService) {
      var handlerInfos = state.handlerInfos;
      var appCache = this._bucketCache;

      for (var i = 0; i < handlerInfos.length; ++i) {
        var qpMeta = this._getQPMeta(handlerInfos[i]);

        if (!qpMeta) {
          continue;
        }

        for (var j = 0, qpLen = qpMeta.qps.length; j < qpLen; ++j) {
          var qp = qpMeta.qps[j];

          var presentProp = qp.prop in queryParams && qp.prop || qp.scopedPropertyName in queryParams && qp.scopedPropertyName || qp.urlKey in queryParams && qp.urlKey;

          (true && !(function () {
            if (qp.urlKey === presentProp) {
              return true;
            }

            if (_fromRouterService) {
              return false;
            }

            return true;
          }()) && (0, _emberDebug.assert)('You passed the `' + presentProp + '` query parameter during a transition into ' + qp.route.routeName + ', please update to ' + qp.urlKey, function () {
            if (qp.urlKey === presentProp) {
              return true;
            }if (_fromRouterService) {
              return false;
            }return true;
          }()));


          if (presentProp) {
            if (presentProp !== qp.scopedPropertyName) {
              queryParams[qp.scopedPropertyName] = queryParams[presentProp];
              delete queryParams[presentProp];
            }
          } else {
            var cacheKey = (0, _utils.calculateCacheKey)(qp.route.fullRouteName, qp.parts, state.params);
            queryParams[qp.scopedPropertyName] = appCache.lookup(cacheKey, qp.prop, qp.defaultValue);
          }
        }
      }
    },
    _scheduleLoadingEvent: function (transition, originRoute) {
      this._cancelSlowTransitionTimer();
      this._slowTransitionTimer = _emberMetal.run.scheduleOnce('routerTransitions', this, '_handleSlowTransition', transition, originRoute);
    },


    currentState: null,
    targetState: null,

    _handleSlowTransition: function (transition, originRoute) {
      if (!this._routerMicrolib.activeTransition) {
        // Don't fire an event if we've since moved on from
        // the transition that put us in a loading state.
        return;
      }

      this.set('targetState', _router_state.default.create({
        emberRouter: this,
        routerJs: this._routerMicrolib,
        routerJsState: this._routerMicrolib.activeTransition.state
      }));

      transition.trigger(true, 'loading', transition, originRoute);
    },
    _cancelSlowTransitionTimer: function () {
      if (this._slowTransitionTimer) {
        _emberMetal.run.cancel(this._slowTransitionTimer);
      }
      this._slowTransitionTimer = null;
    },
    _markErrorAsHandled: function (errorGuid) {
      this._handledErrors[errorGuid] = true;
    },
    _isErrorHandled: function (errorGuid) {
      return this._handledErrors[errorGuid];
    },
    _clearHandledError: function (errorGuid) {
      delete this._handledErrors[errorGuid];
    },
    _getEngineInstance: function (_ref) {
      var name = _ref.name,
          instanceId = _ref.instanceId,
          mountPoint = _ref.mountPoint;

      var engineInstances = this._engineInstances;

      if (!engineInstances[name]) {
        engineInstances[name] = Object.create(null);
      }

      var engineInstance = engineInstances[name][instanceId];

      if (!engineInstance) {
        var owner = (0, _emberUtils.getOwner)(this);

        (true && !(owner.hasRegistration('engine:' + name)) && (0, _emberDebug.assert)('You attempted to mount the engine \'' + name + '\' in your router map, but the engine can not be found.', owner.hasRegistration('engine:' + name)));


        engineInstance = owner.buildChildEngineInstance(name, {
          routable: true,
          mountPoint: mountPoint
        });

        engineInstance.boot();

        engineInstances[name][instanceId] = engineInstance;
      }

      return engineInstance;
    }
  });

  /*
    Helper function for iterating over routes in a set of handlerInfos that are
    at or above the given origin route. Example: if `originRoute` === 'foo.bar'
    and the handlerInfos given were for 'foo.bar.baz', then the given callback
    will be invoked with the routes for 'foo.bar', 'foo', and 'application'
    individually.
  
    If the callback returns anything other than `true`, then iteration will stop.
  
    @private
    @param {Route} originRoute
    @param {Array<HandlerInfo>} handlerInfos
    @param {Function} callback
    @return {Void}
   */
  function forEachRouteAbove(originRoute, handlerInfos, callback) {
    var originRouteFound = false;

    for (var i = handlerInfos.length - 1; i >= 0; --i) {
      var handlerInfo = handlerInfos[i];
      var route = handlerInfo.handler;

      if (originRoute === route) {
        originRouteFound = true;
      }

      if (!originRouteFound) {
        continue;
      }

      if (callback(route) !== true) {
        return;
      }
    }
  }

  // These get invoked when an action bubbles above ApplicationRoute
  // and are not meant to be overridable.
  var defaultActionHandlers = {
    willResolveModel: function (transition, originRoute) {
      originRoute.router._scheduleLoadingEvent(transition, originRoute);
    },
    error: function (error, transition, originRoute) {
      var handlerInfos = transition.state.handlerInfos;
      var router = originRoute.router;

      forEachRouteAbove(originRoute, handlerInfos, function (route) {
        // Check for the existence of an 'error' route.
        // We don't check for an 'error' route on the originRoute, since that would
        // technically be below where we're at in the route hierarchy.
        if (originRoute !== route) {
          var errorRouteName = findRouteStateName(route, 'error');
          if (errorRouteName) {
            var _errorId = (0, _emberUtils.guidFor)(error);
            router._markErrorAsHandled(_errorId);
            router.intermediateTransitionTo(errorRouteName, error);
            return false;
          }
        }

        // Check for an 'error' substate route
        var errorSubstateName = findRouteSubstateName(route, 'error');
        if (errorSubstateName) {
          var errorId = (0, _emberUtils.guidFor)(error);
          router._markErrorAsHandled(errorId);
          router.intermediateTransitionTo(errorSubstateName, error);
          return false;
        }

        return true;
      });

      logError(error, 'Error while processing route: ' + transition.targetName);
    },
    loading: function (transition, originRoute) {
      var handlerInfos = transition.state.handlerInfos;
      var router = originRoute.router;

      forEachRouteAbove(originRoute, handlerInfos, function (route) {
        // Check for the existence of a 'loading' route.
        // We don't check for a 'loading' route on the originRoute, since that would
        // technically be below where we're at in the route hierarchy.
        if (originRoute !== route) {
          var loadingRouteName = findRouteStateName(route, 'loading');
          if (loadingRouteName) {
            router.intermediateTransitionTo(loadingRouteName);
            return false;
          }
        }

        // Check for loading substate
        var loadingSubstateName = findRouteSubstateName(route, 'loading');
        if (loadingSubstateName) {
          router.intermediateTransitionTo(loadingSubstateName);
          return false;
        }

        // Don't bubble above pivot route.
        return transition.pivotHandler !== route;
      });
    }
  };

  function logError(_error, initialMessage) {
    var errorArgs = [];
    var error = void 0;
    if (_error && typeof _error === 'object' && typeof _error.errorThrown === 'object') {
      error = _error.errorThrown;
    } else {
      error = _error;
    }

    if (initialMessage) {
      errorArgs.push(initialMessage);
    }

    if (error) {
      if (error.message) {
        errorArgs.push(error.message);
      }
      if (error.stack) {
        errorArgs.push(error.stack);
      }

      if (typeof error === 'string') {
        errorArgs.push(error);
      }
    }

    _emberConsole.default.error.apply(this, errorArgs);
  }

  /**
    Finds the name of the substate route if it exists for the given route. A
    substate route is of the form `route_state`, such as `foo_loading`.
  
    @private
    @param {Route} route
    @param {String} state
    @return {String}
  */
  function findRouteSubstateName(route, state) {
    var owner = (0, _emberUtils.getOwner)(route);
    var routeName = route.routeName,
        fullRouteName = route.fullRouteName,
        router = route.router;


    var substateName = routeName + '_' + state;
    var substateNameFull = fullRouteName + '_' + state;

    return routeHasBeenDefined(owner, router, substateName, substateNameFull) ? substateNameFull : '';
  }

  /**
    Finds the name of the state route if it exists for the given route. A state
    route is of the form `route.state`, such as `foo.loading`. Properly Handles
    `application` named routes.
  
    @private
    @param {Route} route
    @param {String} state
    @return {String}
  */
  function findRouteStateName(route, state) {
    var owner = (0, _emberUtils.getOwner)(route);
    var routeName = route.routeName,
        fullRouteName = route.fullRouteName,
        router = route.router;


    var stateName = routeName === 'application' ? state : routeName + '.' + state;
    var stateNameFull = fullRouteName === 'application' ? state : fullRouteName + '.' + state;

    return routeHasBeenDefined(owner, router, stateName, stateNameFull) ? stateNameFull : '';
  }

  /**
    Determines whether or not a route has been defined by checking that the route
    is in the Router's map and the owner has a registration for that route.
  
    @private
    @param {Owner} owner
    @param {Ember.Router} router
    @param {String} localName
    @param {String} fullName
    @return {Boolean}
  */
  function routeHasBeenDefined(owner, router, localName, fullName) {
    var routerHasRoute = router.hasRoute(fullName);
    var ownerHasRoute = owner.hasRegistration('template:' + localName) || owner.hasRegistration('route:' + localName);
    return routerHasRoute && ownerHasRoute;
  }

  function triggerEvent(handlerInfos, ignoreFailure, args) {
    var name = args.shift();

    if (!handlerInfos) {
      if (ignoreFailure) {
        return;
      }
      throw new _emberDebug.Error('Can\'t trigger action \'' + name + '\' because your app hasn\'t finished transitioning into its first route. To trigger an action on destination routes during a transition, you can call `.send()` on the `Transition` object passed to the `model/beforeModel/afterModel` hooks.');
    }

    var eventWasHandled = false;
    var handlerInfo = void 0,
        handler = void 0,
        actionHandler = void 0;

    for (var i = handlerInfos.length - 1; i >= 0; i--) {
      handlerInfo = handlerInfos[i];
      handler = handlerInfo.handler;
      actionHandler = handler && handler.actions && handler.actions[name];
      if (actionHandler) {
        if (actionHandler.apply(handler, args) === true) {
          eventWasHandled = true;
        } else {
          // Should only hit here if a non-bubbling error action is triggered on a route.
          if (name === 'error') {
            var errorId = (0, _emberUtils.guidFor)(args[0]);
            handler.router._markErrorAsHandled(errorId);
          }
          return;
        }
      }
    }

    var defaultHandler = defaultActionHandlers[name];
    if (defaultHandler) {
      defaultHandler.apply(null, args);
      return;
    }

    if (!eventWasHandled && !ignoreFailure) {
      throw new _emberDebug.Error('Nothing handled the action \'' + name + '\'. If you did handle the action, this error can be caused by returning true from an action handler in a controller, causing the action to bubble.');
    }
  }

  function calculatePostTransitionState(emberRouter, leafRouteName, contexts) {
    var state = emberRouter._routerMicrolib.applyIntent(leafRouteName, contexts);
    var handlerInfos = state.handlerInfos,
        params = state.params;


    for (var i = 0; i < handlerInfos.length; ++i) {
      var handlerInfo = handlerInfos[i];

      // If the handlerInfo is not resolved, we serialize the context into params
      if (!handlerInfo.isResolved) {
        params[handlerInfo.name] = handlerInfo.serialize(handlerInfo.context);
      } else {
        params[handlerInfo.name] = handlerInfo.params;
      }
    }
    return state;
  }

  function updatePaths(router) {
    var infos = router._routerMicrolib.currentHandlerInfos;
    if (infos.length === 0) {
      return;
    }

    var path = EmberRouter._routePath(infos);
    var currentRouteName = infos[infos.length - 1].name;
    var currentURL = router.get('location').getURL();

    (0, _emberMetal.set)(router, 'currentPath', path);
    (0, _emberMetal.set)(router, 'currentRouteName', currentRouteName);
    (0, _emberMetal.set)(router, 'currentURL', currentURL);

    var appController = (0, _emberUtils.getOwner)(router).lookup('controller:application');

    if (!appController) {
      // appController might not exist when top-level loading/error
      // substates have been entered since ApplicationRoute hasn't
      // actually been entered at that point.
      return;
    }

    if (!('currentPath' in appController)) {
      (0, _emberMetal.defineProperty)(appController, 'currentPath');
    }

    (0, _emberMetal.set)(appController, 'currentPath', path);

    if (!('currentRouteName' in appController)) {
      (0, _emberMetal.defineProperty)(appController, 'currentRouteName');
    }

    (0, _emberMetal.set)(appController, 'currentRouteName', currentRouteName);
  }

  EmberRouter.reopenClass({
    router: null,

    map: function (callback) {
      if (!this.dslCallbacks) {
        this.dslCallbacks = [];
        this.reopenClass({ dslCallbacks: this.dslCallbacks });
      }

      this.dslCallbacks.push(callback);

      return this;
    },
    _routePath: function (handlerInfos) {
      var path = [];

      // We have to handle coalescing resource names that
      // are prefixed with their parent's names, e.g.
      // ['foo', 'foo.bar.baz'] => 'foo.bar.baz', not 'foo.foo.bar.baz'

      function intersectionMatches(a1, a2) {
        for (var i = 0; i < a1.length; ++i) {
          if (a1[i] !== a2[i]) {
            return false;
          }
        }
        return true;
      }

      var name = void 0,
          nameParts = void 0,
          oldNameParts = void 0;
      for (var i = 1; i < handlerInfos.length; i++) {
        name = handlerInfos[i].name;
        nameParts = name.split('.');
        oldNameParts = slice.call(path);

        while (oldNameParts.length) {
          if (intersectionMatches(oldNameParts, nameParts)) {
            break;
          }
          oldNameParts.shift();
        }

        path.push.apply(path, nameParts.slice(oldNameParts.length));
      }

      return path.join('.');
    }
  });

  function didBeginTransition(transition, router) {
    var routerState = _router_state.default.create({
      emberRouter: router,
      routerJs: router._routerMicrolib,
      routerJsState: transition.state
    });

    if (!router.currentState) {
      router.set('currentState', routerState);
    }
    router.set('targetState', routerState);

    transition.promise = transition.catch(function (error) {
      var errorId = (0, _emberUtils.guidFor)(error);

      if (router._isErrorHandled(errorId)) {
        router._clearHandledError(errorId);
      } else {
        throw error;
      }
    });
  }

  function resemblesURL(str) {
    return typeof str === 'string' && (str === '' || str[0] === '/');
  }

  function forEachQueryParam(router, handlerInfos, queryParams, callback) {
    var qpCache = router._queryParamsFor(handlerInfos);

    for (var key in queryParams) {
      if (!queryParams.hasOwnProperty(key)) {
        continue;
      }
      var value = queryParams[key];
      var qp = qpCache.map[key];

      callback(key, value, qp);
    }
  }

  function findLiveRoute(liveRoutes, name) {
    if (!liveRoutes) {
      return;
    }
    var stack = [liveRoutes];
    while (stack.length > 0) {
      var test = stack.shift();
      if (test.render.name === name) {
        return test;
      }
      var outlets = test.outlets;
      for (var outletName in outlets) {
        stack.push(outlets[outletName]);
      }
    }
  }

  function appendLiveRoute(liveRoutes, defaultParentState, renderOptions) {
    var target = void 0;
    var myState = {
      render: renderOptions,
      outlets: Object.create(null),
      wasUsed: false
    };
    if (renderOptions.into) {
      target = findLiveRoute(liveRoutes, renderOptions.into);
    } else {
      target = defaultParentState;
    }
    if (target) {
      (0, _emberMetal.set)(target.outlets, renderOptions.outlet, myState);
    } else {
      if (renderOptions.into) {
        (true && !(false) && (0, _emberDebug.deprecate)('Rendering into a {{render}} helper that resolves to an {{outlet}} is deprecated.', false, {
          id: 'ember-routing.top-level-render-helper',
          until: '3.0.0',
          url: 'https://emberjs.com/deprecations/v2.x/#toc_rendering-into-a-render-helper-that-resolves-to-an-outlet'
        }));


        // Megahax time. Post-3.0-breaking-changes, we will just assert
        // right here that the user tried to target a nonexistent
        // thing. But for now we still need to support the `render`
        // helper, and people are allowed to target templates rendered
        // by the render helper. So instead we defer doing anyting with
        // these orphan renders until afterRender.
        appendOrphan(liveRoutes, renderOptions.into, myState);
      } else {
        liveRoutes = myState;
      }
    }
    return {
      liveRoutes: liveRoutes,
      ownState: myState
    };
  }

  function appendOrphan(liveRoutes, into, myState) {
    if (!liveRoutes.outlets.__ember_orphans__) {
      liveRoutes.outlets.__ember_orphans__ = {
        render: {
          name: '__ember_orphans__'
        },
        outlets: Object.create(null)
      };
    }
    liveRoutes.outlets.__ember_orphans__.outlets[into] = myState;
    _emberMetal.run.schedule('afterRender', function () {
      (true && !(liveRoutes.outlets.__ember_orphans__.outlets[into].wasUsed) && (0, _emberDebug.assert)('You attempted to render into \'' + into + '\' but it was not found', liveRoutes.outlets.__ember_orphans__.outlets[into].wasUsed));
    });
  }

  function representEmptyRoute(liveRoutes, defaultParentState, route) {
    // the route didn't render anything
    var alreadyAppended = findLiveRoute(liveRoutes, route.routeName);
    if (alreadyAppended) {
      // But some other route has already rendered our default
      // template, so that becomes the default target for any
      // children we may have.
      return alreadyAppended;
    } else {
      // Create an entry to represent our default template name,
      // just so other routes can target it and inherit its place
      // in the outlet hierarchy.
      defaultParentState.outlets.main = {
        render: {
          name: route.routeName,
          outlet: 'main'
        },
        outlets: {}
      };
      return defaultParentState;
    }
  }

  (0, _emberMetal.deprecateProperty)(EmberRouter.prototype, 'router', '_routerMicrolib', {
    id: 'ember-router.router',
    until: '2.16',
    url: 'https://emberjs.com/deprecations/v2.x/#toc_ember-router-router-renamed-to-ember-router-_routermicrolib'
  });

  exports.default = EmberRouter;
});