enifed('ember-application/system/engine-parent', ['exports', 'ember-utils'], function (exports, _emberUtils) {
  'use strict';

  exports.ENGINE_PARENT = undefined;
  exports.getEngineParent = getEngineParent;
  exports.setEngineParent = setEngineParent;
  var ENGINE_PARENT = exports.ENGINE_PARENT = (0, _emberUtils.symbol)('ENGINE_PARENT');

  /**
    `getEngineParent` retrieves an engine instance's parent instance.
  
    @method getEngineParent
    @param {EngineInstance} engine An engine instance.
    @return {EngineInstance} The parent engine instance.
    @for Ember
    @private
  */
  function getEngineParent(engine) {
    return engine[ENGINE_PARENT];
  }

  /**
    `setEngineParent` sets an engine instance's parent instance.
  
    @method setEngineParent
    @param {EngineInstance} engine An engine instance.
    @param {EngineInstance} parent The parent engine instance.
    @private
  */
  function setEngineParent(engine, parent) {
    engine[ENGINE_PARENT] = parent;
  }
});